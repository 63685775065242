<template>
  <Table
    :headers="earningHeaders"
    :items="earningsData"
    class="mt-2"
    style="width: 1400px"
    :has-checkbox="true"
    aria-label="earnings table"
    :loading="false"
    :has-number="false"
    @rowSelected="handleSelected($event)"
  >
    <template v-slot:item="{ item }">
      <span v-if="item.payItemName" class="tw--mt-40">
        <div class="tw-ml-4 tw--mt-4">
          <div class="font-bold text-darkPurple">
            {{ item.data.payItemName }}
          </div>
          <div class="uppercase text-xs text-romanSilver">
            {{ item.data.rateType ? item.data.rateType.label : "-" }}
          </div>
        </div>
      </span>
      <span v-else-if="item.outstandingAmount" class="tw-pl-4">
        <Badge
          :label="item.data.outstandingAmount"
          variant="primary"
          background-color="rgba(242, 201, 76, 0.15)"
          color="#E99323"
          style="padding: 8px; height: 33px"
        />
      </span>
      <span v-else-if="item.currentAmount" class="tw-pl-4">
        <Badge
          :label="convertToCurrency(item.data.currentAmount)"
          variant="primary"
          background-color="rgba(19, 181, 106, 0.15)"
          color="#13B56A"
          style="padding: 8px; height: 33px"
      /></span>
      <span v-else-if="item.amountDue" class="tw-pl-4">
        <Badge
          :label="convertToCurrency(item.data.amountDue)"
          variant="primary"
          background-color="rgba(234, 60, 83, 0.15)"
          color="#EA3C53"
          style="padding: 8px; height: 33px"
        />
      </span>
      <span v-else-if="item.rateValue" class="tw-pl-4">
        {{ checkRateType(item.data) }}
      </span>
      <span v-else-if="item.amount" class>
        <CText
          class
          style="width: 131px"
          min="1"
          @change="checkValue(item.data)"
          type="number"
          :disabled="handleCheckbox(item.data.id)"
          :outline="true"
          v-model="item.data.amount"
        />
      </span>
    </template>
  </Table>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CText from "@scelloo/cloudenly-ui/src/components/text";

export default {
  name: "EarningsTab",
  components: {
    Table,
    Badge,
    CText,
  },
  props: ["earningsData"],
  data() {
    return {
      checkboxes: [],
      earningHeaders: [
        { title: "Pay Item", value: "payItemName" },
        { title: "Rate ", value: "rateValue", width: 15 },
        { title: "Outstanding", value: "outstandingAmount", width: 15 },
        { title: "Current Earning", value: "currentAmount", width: 15 },
        { title: "Amount Due", value: "amountDue", width: 15 },
        { title: "Add to Payroll", value: "amount", width: 15 },
      ],
    };
  },
  methods: {
    handleCheckbox(id) {
      if (!this.checkboxes.length) {
        return true;
      }
      this.checkboxes.forEach((item) => {
        if (item.id === id) {
          return false;
        }
        return true;
      });
      return null;
    },
    async handleSelected(value) {
      if (value.length > 0) {
        try {
          await this.$handlePrivilege(
            "variableEarnings",
            "editVariableEarningPay"
          );
          this.checkboxes = value;
          this.$emit("rowSelected", value);
        } catch (error) {
          this.$toasted.error(
            "Sorry you do not have privilege to perform this action",
            { duration: 6000 }
          );
        }
      }
    },
    checkValue(value) {
      const check = this.checkboxes.find(
        (item) => item.paySettingId === value.paySettingId
      );
      if (!check) {
        this.checkboxes.push(value);
      }
    },
    checkRateType(value) {
      const rateLabel = value.rateType.placeholder;
      switch (rateLabel.toString()) {
        case "Enter Percentage":
          return `${value.rateValue}%`;

        case "Enter Multiple (eg x 1.5)":
          return `${value.rateValue}x`;

        case "Enter Amount":
          return `₦${value.rateValue}`;

        case "Enter Daily Rate":
          return `₦${value.rateValue}`;

        default:
          return `-`;
      }
    },
  },
};
</script>
