<template>
  <Table
    :headers="earningHeaders"
    :items="deductionsData"
    class="mt-2"
    style="width: 1400px;"
    :has-checkbox="true"
    :loading="false"
    :has-number="false"
    @rowSelected="handleSelected($event)"
  >
     <template v-slot:item="{ item }">
              <span v-if="item.payItemName" class="tw-ml-10 tw--mt-40">
                <div class="tw-ml-4 tw--mt-4">
                  <div class="font-bold text-darkPurple">
                    {{ item.data.payItemName }}
                  </div>
                  <div class="uppercase text-xs text-romanSilver">
                    {{ item.data.rateType.label }}
                  </div>
                </div>
              </span>
              <!-- <span v-else-if="item.quantity" class="tw-pl-4">{{item.data.quantity}}</span> -->
              <span v-else-if="item.outstandingAmount" class="tw-pl-4">
                <Badge
          :label="item.data.outstandingAmount"
          variant="primary"
          background-color="rgba(242, 201, 76, 0.15)"
          color="#E99323"
          style="padding: 8px; height: 33px;"
        />
              </span>
  <span v-else-if="item.currentAmount" class="tw-pl-4">
               <Badge
          :label="convertToCurrency(item.data.currentAmount)"
          variant="primary"
          background-color="rgba(19, 181, 106, 0.15)"
          color="#13B56A"
          style="padding: 8px; height: 33px;"
        /></span>
              <span v-else-if="item.amountDue" class="tw-pl-4">
                  <Badge
          :label="convertToCurrency(item.data.amountDue)"
          variant="primary"
          background-color="rgba(234, 60, 83, 0.15)"
          color="#EA3C53"
          style="padding: 8px; height: 33px;"
        />
              </span>
              <span v-else-if="item.rateType" class="tw-pl-4">
                {{ checkRateType(item.data) }}
              </span>
              <span v-else-if="item.amount" class>
                <CText
                  class
                  style="width: 131px"
                  :icon="naira"
                  min="1"
                  @change="checkValue(item.data)"
                  type="number"
                  :outline="true"
                  v-model="item.data.amount"
                />
              </span>
            </template>
  </Table>
</template>

<script>
import Table from '@scelloo/cloudenly-ui/src/components/table';
// import Checkbox from '@scelloo/cloudenly-ui/src/components/checkbox';
import Badge from '@scelloo/cloudenly-ui/src/components/badge';
import CText from '@scelloo/cloudenly-ui/src/components/text';
// import Menu from '@/components/Menu';
// import Icon from '@/components/Icon';

export default {
  name: "DeductionsTab",
  components: {
    Table,
    // Checkbox,
    Badge,
    CText,
  },
  props:['deductionsData'],
  data() {
    return {
   earningHeaders: [
        // { title: "", value: "id", width: 2 },
        { title: "Pay Item", value: "payItemName", width: 15 },
        { title: "Rate Input ", value: "settingType", width: 15 },
        { title: "Rate ", value: "rateType", width: 15 },
        { title: "Outstanding", value: "outstandingAmount", width: 15 },
        { title: "Currrent Earning", value: "currentAmount", width: 15 },
        { title: "Amount Due", value: "amountDue", width: 15 },
        { title: "Add to Payroll", value: "amount", width: 15 }
      ],

    };
  },
  methods: {
    async handleSelected(value) {
      if (value.length > 0) {
        try {
          await this.$handlePrivilege("variableEarnings", "editVariableEarningPay");
          this.$emit("rowSelected", value);
        } catch (error) {
          this.$toasted.error(
            "Sorry you do not have privilege to perform this action",
            { duration: 6000 }
          );
        }
      }
    },
    checkValue(value) {
      const check = this.checkboxes.find(item => item.paySettingId === value.paySettingId);
      if (!check) {
        this.checkboxes.push(value);
      }
    },
        checkRateType(value) {
      // const rateValue = value.rateType.dataType;
      const rateLabel = value.rateType.placeholder;
      switch (rateLabel.toString()) {
        case "Enter Percentage":
          return `%`;

        case "Enter Multiple (eg x 1.5)":
          return `x`;

        case "Enter Amount":
          return `₦`;

        case "Enter Daily Rate":
          return `₦`;


        default:
          return `-`;

      }
    }
  },
  mounted(){
    // eslint-disable-next-line no-console
    console.log(this.deductionsData);
  }


};
</script>
